import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Height`}</strong></p>
    <p>{`In my family, each member has a different height,`}<br parentName="p"></br>{`
`}{`leading to different interests.`}<br parentName="p"></br>{`
`}{`To start, my mom’s side of the family is like lilliputs compared to my dad’s side of the family.`}<br parentName="p"></br>{`
`}{`My mom is the shortest in the family. Everyone always tells me I act and look more like my mom than my dad. My mom and I have similar personalities, interests, and height...`}<br parentName="p"></br>{`
`}{`When she was younger, she used to be a dancer. Her parents decided to put her into a dancing team because she was short, and would be showcased most from the group.`}<br parentName="p"></br>{`
`}{`She competed with her team almost every week, which helped her gain a lot of lower and upper body strength. As one of the shortest members of the team, she would dance right in front of the audience as she wouldn’t be visible in the back.`}</p>
    <p>{`My sister and my dad are almost the same height. Funny enough, they have almost identical personalities and interests in life. They both started as young sports enthusiasts, particularly in football. My dad was and still plays football very well, and my sister was very successful in football, including being captain of the team for a very long time, she is usually the leader for several things. My sister was always a bit taller than the average height for her age, and that helped her be adventurous in life. For instance, she could always go on rollercoaster rides because she was taller than the minimum height at a young age.`}</p>
    <p>{`My sister is a very brave woman, and I think her past experiences relating to height helped her become very courageous. Now my sister is studying economics just like my dad did and is planning to follow in my dad’s footsteps and go into the economy field`}</p>
    <p>{`Me? I am my mom. Bullied for height and am interested in the arts. I have never been very interested in sports.`}<br parentName="p"></br>{`
`}{`I wanted to participate in many sports when I was younger, although I was always too short and too scared to join a team.`}</p>
    <p>{`My sister at the age of 12 was already interested in economics, my dad was already an economist and he’d take my sister to his lectures. My mom was an interior designer, and she already knew that math and money wouldn’t be my thing. So what does she do? She tells me I have to go into music. One day out of the blue, she asks me,  “violin or piano?”`}<br parentName="p"></br>{`
`}{`With just those six words, and me replying with 1 word... my life was about to change.`}<br parentName="p"></br>{`
`}{`“Violin”- I reply.`}<br parentName="p"></br>{`
`}{`I turned over the leaf to a new side.`}</p>
    <p><strong parentName="p">{`One-Eighth`}</strong></p>
    <p>{`I was starting a new chapter in my life, feeling like I was about to be the next Mozart.`}<br parentName="p"></br>{`
`}{`I imagined that I would attend a music school that had a huge theatre with golden bars, ceiling paintings, white walls, and chandeliers like the ones in churches. I imagined that I would have a group of kids with me, playing and learning the violin, and one day we could go together and be a successful classical band.`}</p>
    <p>{`As we turned into a driveway near my street,`}<br parentName="p"></br>{`
`}{`I saw a beige building,  which looked like a police station with no enthusiasm around. I was ready to pass by it and continue on my adventurous walk to the music school. My mom started walking closer to the beige building, I tried to turn back to the road but every time I attempted that, I felt her boney hands squeezing mine tighter and tighter...`}<br parentName="p"></br>{`
`}{`The confusion was circling my brain. Why am I going here? Where are we going?`}</p>
    <p>{`My mom dragged my body to this building, and when we got inside she confidently greeted another old woman there and continued going somewhere.`}<br parentName="p"></br>{`
`}{`We walked into a room...In the room was an old woman. Her ocean-blue eyes stared straight into my dark hazelnut eyes, she opened her mouth and started saying “Come here, Sofia”.`}</p>
    <p>{`With each step, I felt weaker. I was losing the ability to hear. My stomach was filled with butterflies. Who is this woman?`}<br parentName="p"></br>{`
`}{`There was a  brown and chipped piano near her.`}</p>
    <p>{`Without background context, she points to it and says, “play me something on the piano now, or you aren’t my student for violin”`}<br parentName="p"></br>{`
`}{`My heart was in my throat. My arms and legs were weak noodles. The only time I tried playing the piano was with my dad, we played the classic “Do Re Mi”. I was going to play that for her, but the stress had frozen my body and I barely got to that piano.`}</p>
    <p>{`What kind of a first-class is this?!`}<br parentName="p"></br>{`
`}{`Where is the opera theatre? Where are the other kids? Why is my teacher strict? Why am I playing the piano, not the violin? I imagined this as a USSR type of a first-class...`}<br parentName="p"></br>{`
`}{`I thought that I wouldn’t survive before the lesson ended, and my mom thought that I could learn the violin with her twice a week?!`}<br parentName="p"></br>{`
`}{`Getting to the piano, my trembling fingers pressed each key, I needed to play everything right or I would be burned there to my ashes.`}</p>
    <p>{`I finished playing. I look at her.  She looked at me. Strong, scary eye contact.`}<br parentName="p"></br>{`
`}{`She says to me, “You are the moth to music being the flame...welcome to being my student”`}<br parentName="p"></br>{`
`}{`How I played the piano showed to her that I am “musical” I wasn’t sure, but at least she said something that made that dark cloud fly away from me.`}<br parentName="p"></br>{`
`}{`I smiled, that smile felt like my first smile ever...`}<br parentName="p"></br>{`
`}{`She hands me a toy like violin and says this is the first size of a violin- a One Eighth.`}<br parentName="p"></br>{`
`}{`“Is this what I will have to learn?”`}<br parentName="p"></br>{`
`}{`“Yes, it is”.`}</p>
    <p><strong parentName="p">{`The Treble Clef Building`}</strong></p>
    <p>{`I called this school the Treble Clef building because there was a big treble clef at the back of it.`}<br parentName="p"></br>{`
`}{`At the front of the building, there’s a big red metal door, with one grey stair leading up to it. Near the entrance, there is one long bench, and the back of the bench is the note staff with many different notes on it. I later realized that whoever made that note staff drew the sticks the wrong way, so no one will ever know what the melody is.`}</p>
    <p>{`The bench is chaos. It’s always chaotic there. One mother will be screaming into the phone about job issues, then there is a grandma who plays with a 5-year-old while the older sibling is having their lesson, and a stressed guitar player that is trying to find his notes in his bag while everyone is screaming. My mom used to take me to my lessons and wait there until I finished, but after a couple of years, we both agreed that it’s better if I go there alone since she doesn’t need all that mess.`}</p>
    <p>{`When you enter the building, it’s a jungle.`}<br parentName="p"></br>{`
`}{`There are plants all over the reception area with every type of vine you can imagine, as well as small palm trees in each corner of the room.`}<br parentName="p"></br>{`
`}{`This jungle also contains animals, and by animals, I mean bored 5 years old’s playing hide and seek on each floor.`}</p>
    <p>{`There are 3 floors all together in this school. That’s why if you are in the middle of a lesson and it feels like a gorilla is jumping on the floor above you...well, it’s the children.`}</p>
    <p>{`Luckily, my violin classroom is right beside the reception on the first floor. On the first floor, there is also the performance room where I don’t remember being without stressing, and also other import staff offices. There are also other classrooms for other instruments, mainly string instruments. When you walk up the stairs from the reception to the second floor, you will want to go back downstairs.`}</p>
    <p>{`Luckily I only take 2 classes on the second floor; Solfeggio and Music history.`}<br parentName="p"></br>{`
`}{`The second floor is the weirdest combination of sounds you can get, it honestly reminds me of primary school. Mainly, there are brass instruments getting played, so you hear off-key trumpets, trombones, AND tubas at the same time. There are also singing lessons going on and hearing the voice with trombones that are changing from flats to sharps isn’t a good combination. Following all of that, with the music history classrooms, there are operas being played on the loudest speakers you could imagine. I have never seen a parent on that floor and I am not surprised. No adult wants that experience.`}</p>
    <p>{`The third floor is in the sky. I have no idea where it is. I have never been there. I know it’s there, there are windows from the outside. What’s inside? Maybe I will know one day...`}</p>
    <p><strong parentName="p">{`The Grey Curly Haired Lady`}</strong></p>
    <p>{`Where could you find me on a Tuesday or Friday at 5:30 pm? My third home- my classroom where I play the violin, located on Bastionna street.`}</p>
    <p>{`After school on Tuesdays and Fridays, I would go home for 2 hours to eat and relax, and then I would head to my music school to practice violin with my teacher.`}<br parentName="p"></br>{`
`}{`People constantly ask me who my violin teacher is and how successful she is in the violin industry.`}<br parentName="p"></br>{`
`}{`When I get asked this, I start off by dropping the bombshell and saying she’s 85 years old…`}<br parentName="p"></br>{`
`}{`As I say this, everyone gets shocked and starts to assume that she is a very nice, sweet, old lady who will always compliment me on everything I do. She is the exact opposite of that.`}<br parentName="p"></br>{`
`}{`My dad and I always joke that she’s used to teaching the USSR style, so she still teaches her students born in the 00’s the same way she did in the old days.`}</p>
    <p>{`As from my experience, she is the scariest teacher I have had…`}<br parentName="p"></br>{`
`}{`Instead of resting in her chair as people do in their eighties, she will stand right beside you screaming into your ear what notes you are playing wrong, her cold wrinkly fingers push your finger down onto a string if she thinks the sound is too light, her grey curls will spring up to the top of her head when she’s mad and when she’s disappointed in the way you answered to one of her complicated questions about the piece, her face will lose every emotion it could have and her blue eyes will stare into your soul.`}</p>
    <p>{`While you are playing the violin, it’s hard to hear what she is screaming at the top of her lungs. It sounds like she turned into a hornet and you hear buzzing sounds like “Idiot!” that barely reach your ears from the non-stop playing of the violin. Finally, when you feel like you understand Ukrainian you will hear her shout, “Stop playing, everything’s wrong!” then you will feel partly happy for understanding, but next come the attacks at how you can’t learn anything in life…`}</p>
    <p>{`Even with all of this, she has been teaching at that music school for over 50 years. Now I am used to hearing things that could shoot a bullet into my heart and cause me to start crying my eyes off, which used to happen when I was 5. In the long run, she is who taught me discipline, taking responsibility for my own actions, and being passionate about the things I love.`}</p>
    <p>{`So even though I might spend an afternoon full of “feeling stupid” moments with an energetic 85-year-old with grey curly hair and loud different colored pearl necklaces banging against each other, it’s a fun experience I will never again take for granted.`}</p>
    <p><strong parentName="p">{`Walking Home`}</strong></p>
    <p>{`The street of Bastionna is a dangerous place for its many possible car accidents that can happen on the road, which is why people have to pay lots of attention...`}<br parentName="p"></br>{`
`}{`As soon as you walk out of the music school, you are on the road.`}</p>
    <p>{`The road is quite dangerous for pedestrians because there isn’t a traffic light on the zebra. The drivers are always in the rush to go somewhere, and because Bastionna street doesn’t have many people passing through their day there, and police are rarely found there, so drivers are able to speed all they want and scare children to their last point.`}<br parentName="p"></br>{`
`}{`Luckily, the zebra is brightly painted red and white for drivers to see it...They painted it around 2 years ago on the road. I remember my first impression of the zebra was that it smelled like mushrooms. I laughed because I realized that the colors of the zebra were red and white, just like the Amanita mushroom.`}</p>
    <p>{`The road is quite bumpy, it has many rocks and when you walk on it, it feels like you are walking on needles without their sharp bottoms.`}<br parentName="p"></br>{`
`}{`Down-Left from the zebra, the road continues onto the Pechersk Area. I rarely go there, and if I do. It’s to eat with my family in the delicious Italian restaurant on the corner of the street near the motherland statue. Although when it’s 7 pm and I am exhausted from my day, I will go back home which is up-right of the zebra.`}</p>
    <p>{`Bastionna street is like a hill. As you go down the street, you go down physically too. Although if you go up the street to Mykhailo Boichuka street, you go up to reach the Pecherskiy bridge.`}<br parentName="p"></br>{`
`}{`When I go home, I first go up the hill to the bridge, but then I turn down to Nimenska street, to later turn to my home street.`}<br parentName="p"></br>{`
`}{`Nimenska street is one of my favorite streets in Kyiv. Luckily I live around 5 meters from the turn to the street.`}</p>
    <p>{`On Nimenska, you can find anything. Here are some of my favorite places;`}</p>
    <p>{`At the top of it near the bridge, there is a Georgian place with delicious Khachapuri I used to get for my family on the way home. It’s a tiny brown metal kiosk, although the food is amazing for such a small business. Another place is the handmade soap store, further down from the Georgian Place.`}<br parentName="p"></br>{`
`}{`When you walk inside the store and smell the place, it feels like you reached the Carpathians with just one step. The soaps, the teas, and everything else that is sold there has the best scents and it’s a perfect stress reliever after a tiring violin lesson.`}</p>
    <p>{`There is also a park opposite the handmade store. It’s a small park, although it’s always loud there with all the dogs barking and all the cars trying to park against the small and old curb.`}</p>
    <p>{`Walking home from my music school can be very fun`}</p>
    <p><strong parentName="p">{`The Four Areas`}</strong></p>
    <p>{`People who are often found around Bastionna, are familiar with the concept of the “four areas”.`}<br parentName="p"></br>{`
`}{`To anyone who has not been to Bastionna, they will quickly notice the way people are separated into the four areas.`}</p>
    <p>{`The first area is quite dangerous. It’s a small park across the zebra on Bastionna street. When you get to the small park, you enter “Україна” instead of “Ukraine”. The joke is, that instead of seeing joyful people interacting with each other and sharing their patriotism, you see drug addicts with different types of vodka bottles near them. You can sometimes find the police there kicking those people out of the park.`}<br parentName="p"></br>{`
`}{`Children used to pass that park to go home, like me for example, although in the dark it’s not the best way to go.`}</p>
    <p>{`The second area is the food area, near the small park. The food area is quite hidden, so it might take awhile for you to find it. Usually, you will find parents there, as they will go to buy groceries while their children are in music classes. I rarely go there, although if my mom will take me to the music school, I will usually go to the food area to find her and head home. Since the parents are constantly there, you will see many friendly and familiar interactions between the parents and the shop owners.`}</p>
    <p>{`The third area is a playground right near the music school. This playground is an escape for parents and their younger children who are bored in the music school waiting for their siblings. If you enter that playground, it seems like everyone knows each other and everyone’s friends with each other. I have never seen an argument take place there.`}</p>
    <p>{`The fourth area is the jungle itself. My music school.`}<br parentName="p"></br>{`
`}{`You can hear each type of instrument, children running on each floor, loud applauses,  from hundreds of meters away. The fourth area also has the most success achieved every day…`}</p>
    <p><strong parentName="p">{`Small Reunions`}</strong></p>
    <p>{`Luckily on my way to my music school, I can sometimes see an old lady, sipping her black tea with purple glasses on, standing on her balcony like a security camera, watching Bastionna street. This lady would be my grandma’s cousin.`}</p>
    <p>{`My family found out that she lives on the same street as my music school about two years after I started doing music. She has 2 apartments, and one of them is located here. Whenever I go to my music school, I always make sure to check her balcony and see if she has noticed me yet.`}<br parentName="p"></br>{`
`}{`She treats me like I am her granddaughter who constantly wants to hurt herself because the only words I hear from her are “Sofia where is your scarf?!”, “Sofia your zipper on your coat isn’t zipped to its last point!”, and according to her, these things can lead you to get a cold… when it’s 10 degrees celsius…`}<br parentName="p"></br>{`
`}{`Either way, when I am fully dressed it’s fun to see her, it’s like constant small family reunions. Sometimes I will come a bit earlier and I will head to her apartment to drink tea with her, and listen to all the crimes that have happened on Bastionna in the past four years.`}</p>
    <p>{`My grandma’s cousin always knows everything about that area. Whenever there is a situation where police get involved, she will talk to them because she knows details about her neighbors and her area.`}<br parentName="p"></br>{`
`}{`Even though she knows I play the violin, she has never come to any of my recitals or exams.`}</p>
    <p>{`She loves the sound of the violin, although she says that the music school is too loud for her and it’s out of her comfort zone, so sometimes I will play my piece in her living room.`}</p>
    <p>{`After I play, she will give me some baked treats she recently made.`}<br parentName="p"></br>{`
`}{`One of her biggest passions is baking. She is known for that on the street. Whenever she makes something, her neighbors instantly smell it, and just like at a bus stop, everyone lines up at her door to try what she made.`}</p>
    <p>{`It’s quite nice to have a family relative on a street where I spend lots of time. I am able to interact with her about Kyiv news, try her baked goods, and get compliments on the way I play the violin.`}<br parentName="p"></br>{`
`}{`The chapters I wrote before, about the four areas, and the things that happen on Bastionna street, are mainly written from the information I found from her- the security camera of the street.`}</p>
    <p><strong parentName="p">{`Regretful Thoughts`}</strong></p>
    <p>{`It was March 10th, and I was walking to my Music school. I was exhausted from school and I thought that my music school could be an escape from all of it. Luckily, I only had Music History that day- A class where you relax and watch operas with other kids. As I was near my music school, I realized that I forgot my hat at home. I looked up to my grandma’s cousin’s balcony to see if she was there because if she would be I would get embarrassed by her yelling at how I am not wearing a hat and I might die because of that. She wasn’t… that’s already a good start.`}</p>
    <p>{`As I entered my music school, everyone was as gloomy as I was. I walked up the stairs to the second floor where once again, I found a zoo full of children. I rushed through them and got to my music class. I looked at my phone and realized I was 5 minutes early, so I just stood outside the classroom.`}<br parentName="p"></br>{`
`}{`I looked at all the chipping walls and thought about how all I wanted was a break from school. Waking up early, then going to school, and coming back to more stress when it’s already pitch black wasn’t an activity I wanted to do. I saw some of my friends but I didn’t greet them because I knew I would see them again tomorrow for other classes I had to do in music school.`}</p>
    <p>{`It was 6 pm, and I entered my class. I went to my usual seat. Everyone greeted the teacher, and we continued to watch the same opera we did in the last lesson. I forgot the name of it because I didn’t care about what we were watching. Instead, I was thinking about all the upcoming summatives I had in school. Life was so complicated is all I could think about. “All I want is a break”...`}<br parentName="p"></br>{`
`}{`I remember sitting in the PSI auditorium the next day hearing about the shutting down of schools. My wish came true…`}</p>
    <p>{`In the long run, they were regretful thoughts I would never want to think about again. I took everything for granted and I didn’t realize how much things can change in just one day.`}<br parentName="p"></br>{`
`}{`To this day, I haven’t gone back to my music school. I have only had violin lessons online. All I want is to see those chipped walls, to see my grandma’s cousin, and I can’t believe I am saying this but I also want to get yelled at again for playing the wrong note and get my finger pressed on the right one.`}</p>
    <p>{`Bastionna Street is a true treasure I have in my life. Once I can come and see those four areas again full of familiar people, I will be the happiest person. It’s something I will never take for granted again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      